.itp-header {
  background-color: #002340;
  color: #ffffff;
  padding: 35px;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 50px !important;
}

.itp-logo {
  font-weight: bold;
  margin-left: -15px;
}

.itp-logo-img {
  width: 133px;
}

.itp-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.itp-left-section {
  display: flex;
  align-items: center;
  flex: 1;
}

.itp-text {
  margin-right: 10px;
  font-size: 18px;
}

.itp-search {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.itp-search {
  margin-right: 5px;
}

.itp-icons {
  display: flex;
  align-items: center;
  margin-right: 0px;
  margin-left: 10px;
}

.itp-icon:hover {
  background: #ffffff39;
}

.itp-icon {
  margin-right: 10px;
  border-radius: 50%;
  background: #FFFFFF14;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.itp-icon.menu {
  display: none;
}

.itp-profile {
  display: flex;
  align-items: center;
}

.itp-profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.itp-profile-details {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.itp-profile-name {
  font-size: 14px;
}

.itp-profile-email {
  font-size: 12px;
  color: #ffffffb8;
}

.itp-header-bar {
  height: 30px;
  border: 1px solid #ffffff24;
  margin: 0px 20px !important;
}

@media (max-width: 991px) {
  .itp-search {
    display: none;
  }
}

@media (max-width: 991px) {
  .itp-icon.menu {
    display: flex;
    transform: rotate(180deg);
    transition: all 0.5s;
  }
}

@media (max-width: 991px) {
  .itp-icon.menu.hidden {
    display: flex;
    transform: rotate(0deg);
    transition: all 0.5s;
  }
}

@media (max-width: 644px) {
  .itp-left-section,
  .itp-header-bar {
    display: none;
  }
}

@media (max-width: 644px) {
  .itp-content {
    justify-content: space-between;
  }
}

@media (max-width: 509px) {
  .itp-profile-details {
    display: none;
  }
}

@media (max-width: 387px) {
  .itp-logo-img {
    width: 100px;
  }
}
