.itp-add-contact-subtitle {
  text-align: left;
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-select-add-contact-label {
  text-align: left;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-add-contact-select {
  margin-top: 6px;
  width: 90%;
}

.itp-add-contact-buttons {
  display: flex;
  gap: 8px;
}

.itp-contact-button {
  width: 4rem;
  height: 2rem;
  margin-top: 1.5rem;
}
