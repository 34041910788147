/* body, html{
  margin: 0px !important;
  padding: 0px !important;
} */

.webphone-component{
  position: fixed;
  z-index: 100000;
  left: calc(50% - 127px);
  top: 100px;
  /* width: 100%;
  height: 100%;
  background: red; */
}