.itp-contact-details-label {
  text-align: left;
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-contact-details-p {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}
.itp-contact-details-row-select {
  margin-top: 20px !important;
  margin-bottom: 1.3rem;
}

.itp-icon-plus {
  margin-left: 4px;
  margin-right: 6px;
}

.itp-contact-details-add-field {
  margin-top: 1.7rem;
  margin-bottom: 2rem;
}

.itp-contact-details-separator {
  border: 1px solid #70707073;
  opacity: 1;
  margin-top: -1.5rem;
}

.itp-contact-details-notes-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-contact-details-notes {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-top: 16px;
  margin-bottom: 8px;
}

.itp-contact-details-notes-button {
  margin-left: 1rem;
  white-space: nowrap;
  width: 7rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select__placeholder {
  white-space: nowrap !important;
}

.itp-contact-details-note-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.itp-contact-details-note-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 8px;
  opacity: 1;
  padding: 12px;
  margin-bottom: 12px;
}

.itp-contact-detail-note-img {
  width: 28px;
  height: 28px;
  margin-right: 4px;
}

.itp-contact-details-note-text {
  font: normal normal normal 10px/14px Instrument Sans;
  letter-spacing: 0px;
  color: #4c4c4c99;
  opacity: 1;
  margin-right: 12px;
}

.itp-contact-details-note-content__tile {
  display: flex;
  justify-content: space-between;
}

.itp-contact-details-note-content__content {
  display: flex;
  margin-top: 8px;
}

.icon-text-note {
  margin-top: 2px;
  margin-right: 8px;
}

.itp-contact-detail-button-save {
  margin-top: 1.5rem;
  width: 8rem;
  height: 2rem;
  margin-bottom: 2r8;
}

.itp-contact-detail-add-note {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.itp-contact-details-add-note-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.itp-contact-details-save-notes-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itp-contact-details-edit-note {
  width: 100%;
  margin-top: 12px;
}

.itp-contact-details-new-field {
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 12px;
  background-color: rgba(112, 112, 112, 0.5);
}

.itp-contact-details-new-field__input {
  border: 0;
  text-align: left;
  padding-left: 0.8rem;
  background-color: transparent;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  outline: 0;
}

.itp-contact-details-new-field__input:focus {
  border-bottom: 1px solid gray !important;
}

.itp-contact-details-new-field__button {
  border: 0;
  text-align: left;
  padding-left: 0.8rem;
  background-color: transparent;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-contact-details-new-field__button:hover {
  background-color: gray !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.itp-contact-details-new-field__button.save:hover {
  background-color: gray !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.form-control {
  width: 100% !important;
}

.itp-custom-checkbox-contact-details {
  border: 1px solid gray !important;
}

.itp-edit-contact-container-custom-field {
  position: relative;
}

.itp-edit-contact-custom-field-actions {
  position: absolute;
  background-color: #ffffff;
  width: 6.5rem;
  visibility: hidden;
  top: -3rem;
  /* border: 1px solid #bebfc1; */
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  height: auto;
  box-shadow: #8d8a8a 0px 0px 3px;
}


/* .itp-edit-contact-container-custom-field:has(.itp-label-input-col.col-5.col-sm-5.col-md-5.col-lg-5.col-xl-5.col-xxl-5:hover) > .itp-edit-contact-custom-field-actions {
  visibility: visible;
} */

.itp-edit-contact-container-custom-field:has(.itp-label-input__label:hover) > .itp-edit-contact-custom-field-actions, .itp-edit-contact-custom-field-actions:hover{
  visibility: visible;
}


.itp-label-input__label.option {
  cursor: pointer;
}

.itp-label-input__label.option:hover {
  background-color: rgb(245, 245, 242);
}

.itp-label-border-bottom-property {
  border-bottom: #e3dede solid 1px;
}