.all-container-chat {
  width: 100%;
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  height: 30rem;
  transition: width 0.2s ease;
}

.all-container-chat.resize {
  width: 40%;
  transition: width 0.2s ease;
}

@media (min-width: 1920px) {
  .all-container-chat.resize {
    width: 54%;
  }
}

.margin-info {
  margin-left: 2rem;
}

.all-container-menu {
  width: 0%;
  margin-top: 12px;
  margin-right: 12px;
  height: 30rem;
  margin-left: -0.7rem;
  overflow: auto;
}

.all-container-menu-absolute {
  width: 30%;
  margin-top: 12px;
  height: 30rem;
  position: absolute;
  right: 0;
  transform: translateX(0%);
  transition: transform 0.2s ease-in-out;
  display: block;
  z-index: 2;
}

@media (min-width: 1200px) and (max-width: 1344px) {
  .all-container-menu-absolute {
    width: 35%;
  }
}

@media (min-width: 1067px) and (max-width: 1199px) {
  .all-container-menu-absolute {
    width: 40%;
  }
}

@media (min-width: 992px) and (max-width: 1066px) {
  .all-container-menu-absolute {
    width: 45%;
  }
}

@media (min-width: 877px) and (max-width: 991px) {
  .all-container-menu-absolute {
    width: 38%;
  }
}

@media (min-width: 768px) and (max-width: 876px) {
  .all-container-menu-absolute {
    width: 44%;
  }
}

@media (min-width: 667px) and (max-width: 767px) {
  .all-container-menu-absolute {
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 666px) {
  .all-container-menu-absolute {
    width: 58%;
  }
}

@media (max-width: 575px) {
  .all-container-menu-absolute {
    width: 100%;
  }
}

.no-show {
  width: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease;
}

@media (min-width: 1920px) {
  .all-container-menu {
    width: 22%;
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {
  .all-container-menu {
    width: 30%;
  }
}

@media (min-width: 768px) and (max-width: 1099px) {
  .all-container-chat {
    width: 53%;
  }
}

@media (min-height: 1280px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 75rem;
  }
}

@media (min-height: 883px) and (max-height: 1279px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 60rem;
  }
}

@media (min-height: 800px) and (max-height: 882px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 47rem;
  }
}

@media (min-height: 760px) and (max-height: 799px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 38rem;
  }
}

@media (max-height: 759px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 42rem;
  }
}

.itp-chat-window {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #b7b7b71f;
  border: 0.30000001192092896px solid #70707047;
  border-radius: 8px;
  opacity: 1;
  width: auto;
  height: 100%;
  padding: 4px 20px 8px 20px;
}

.itp-chat-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #b7b7b738;
  border: 0.30000001192092896px solid #70707047;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  width: auto;
  height: 100%;
  overflow: auto;
}

.itp-chat-menu::-webkit-scrollbar {
  width: 2px; /* Ancho del scroll */
}

.itp-chat-menu::-webkit-scrollbar-track {
  background: #8787874d; /* Color de fondo del track (la barra detrás del scroll) */
}

.itp-chat-menu::-webkit-scrollbar-thumb {
  background: rgba(
    112,
    112,
    112,
    0.5
  ); /* Color del thumb (la barra de scroll en sí) */
}

.itp-chat-window-header {
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  margin-top: 8px !important;
  height: 10%;
}

@media (min-width: 1920px) {
  .itp-chat-window__header-containet-img {
    margin-right: -2rem;
  }
}

.itp-chat-window__header-user-img {
  width: 3rem;
  height: 3rem;
}

.itp-chat-window__header-info-user {
  /* margin-left: 4px;
    margin-top: 14px; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: 4px;
  margin-top: 4px !important;
}

@media (min-width: 1200px) and (max-width: 1333px) {
  .itp-chat-window__header-info-user {
    margin-left: 12px;
  }
}

@media (min-width: 1100px) and (max-width: 1199px) {
  .itp-chat-window__header-info-user {
    margin-left: 20px;
  }
}

.itp-chat-window__header-user-name {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-bottom: 0;
  white-space: normal;
}

@media (max-width: 482px) {
  .itp-chat-window__header-user-name {
    margin-left: 1.5rem;
    margin-top: 2px;
  }
}

@media (min-width: 483px) and (max-width: 617px) {
  .itp-chat-window__header-user-name {
    margin-left: 1rem;
    margin-top: 2px;
  }
}

@media (min-width: 618px) and (max-width: 767px) {
  .itp-chat-window__header-user-name {
    margin-left: 0.5rem;
    margin-top: 2px;
  }
}

@media (min-width: 768px) and (max-width: 1099px) {
  .itp-chat-window__header-user-name {
    margin-left: 1.5rem;
    margin-top: 2px;
  }
}

.itp-chat-window__header-last-message {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

@media (max-width: 1099px) {
  .itp-chat-window__header-last-message {
    display: none;
  }
}

.itp-chat-window__header-separator {
  border: 1px solid #707070;
  opacity: 0.15;
  height: 3rem;
}

@media (max-width: 1099px) {
  .itp-chat-window__header-separator {
    display: none;
  }
}

.itp-chat-window__header-chat-enabled {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #51a9f01a 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  margin-top: 8px;
  height: 2rem;
  width: 9rem;
}

@media (max-width: 380px) {
  .itp-chat-window__header-chat-enabled {
    margin-left: -6rem;
    background-color: transparent !important;
  }
}

@media (min-width: 381px) and (max-width: 417px) {
  .itp-chat-window__header-chat-enabled {
    margin-left: -3rem;
    background-color: transparent !important;
  }
}

@media (min-width: 418px) and (max-width: 767px) {
  .itp-chat-window__header-chat-enabled {
    margin-left: -4rem;
    background-color: transparent !important;
  }
}

@media (min-width: 768px) and (max-width: 793px) {
  .itp-chat-window__header-chat-enabled {
    margin-left: -20px;
    background-color: transparent !important;
  }
}

@media (min-width: 794px) and (max-width: 991px) {
  .itp-chat-window__header-chat-enabled {
    margin-left: -20px;
  }
}

@media (min-width: 992px) and (max-width: 1099px) {
  .itp-chat-window__header-chat-enabled {
    margin-left: -5rem;
  }
}

.itp-chat-window__header-text-chat-enabled {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
  white-space: nowrap;
  margin-left: -2rem;
  order: 1 !important;
}

@media (max-width: 1099px) {
  .itp-chat-window__header-text-chat-enabled {
    display: none;
  }
}

.form-check-chat-window {
  margin-top: 4px !important;
  order: 2 !important;
  margin-left: 0.5rem !important;
}

.itp-chat-windiow__header-show-profile {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #1c5586;
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

@media (max-width: 482px) {
  .itp-chat-windiow__header-show-profile {
    display: none;
  }
}

.itp-chat-windiow__header-show-profile-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 6px;
  margin-top: 12px !important;
}

.itp-chat-windiow__header-show-profile-ropdown {
  margin-top: -2px;
}

.itp-chat-window-content {
  height: 75%;
  overflow: auto;
  position: relative;
  display: flex;
}

.itp-chat-window__container-text-loading {
  position: absolute;
  left: 45%;
}

.itp-chat-window__text-loading {
  font: normal normal normal 16px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-chat-window-footer {
  height: 14%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #7070704d;
  border-radius: 6px;
  opacity: 1;
  margin-top: 12px !important;
  position: relative !important;
}

.itp-chat-window-content__you-message-data {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.itp-chat-window-content__name {
  font: normal normal normal 16px/21px Instrument Sans;
  letter-spacing: 0px;
  color: #4c4c4c;
  opacity: 1;
}

.itp-chat-window-content__hour {
  font: normal normal normal 10px/14px Instrument Sans;
  letter-spacing: 0px;
  color: #4c4c4c99;
  opacity: 1;
}

.itp-chat-window-content__you-message-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  position: relative;
}

.itp-chat-window-content__you-message-content {
  background: rgba(31, 61, 87, 0.1) 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid rgba(112, 112, 112, 0.1);
  border-radius: 10px 0px 10px 10px;
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 8px 10px 8px 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
}

.itp-chat-window-content__you-message {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-bottom: 0;
  text-align: left;
}

.itp-chat-window-content__other-message-data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.itp-chat-window-content__other-message-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  position: relative;
}

.itp-chat-window-content__other-message-content {
  background: rgba(176, 219, 255, 0.45) 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid rgba(0, 134, 246, 0.2);
  border-radius: 0px 10px 10px 10px;
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 8px 10px 8px 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-start;
}

.itp-chat-window-content__message {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-bottom: 0;
  text-align: left;
  word-wrap: anywhere;
}

.itp-chat-window-content::-webkit-scrollbar {
  width: 6px; /* Ancho del scroll */
}

.itp-chat-window-content::-webkit-scrollbar-track {
  background: #8787874d; /* Color de fondo del track (la barra detrás del scroll) */
}

.itp-chat-window-content::-webkit-scrollbar-thumb {
  background: rgba(
    112,
    112,
    112,
    0.5
  ); /* Color del thumb (la barra de scroll en sí) */
}

.itp-chat-window-content__container-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;

  position: sticky;
  top: 0;
  background-color: #f7f7f7;
  z-index: 1;
  transition: background-color 0.3s;
}

.itp-chat-window-content__container-separator.sticky {
  /* Estilos cuando el separador está "stick" */
  background-color: #fff;
  /* box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.2); */
}

.fixed-separator {
  position: sticky;
  top: 0;
  z-index: 10;
}

.itp-chat-window-content__text-separator {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.itp-chat-window-content__line-separator {
  border: 1px solid rgba(67, 112, 151, 0.2);
  width: 50%;
}

.itp-chat-window-content__download-message-content {
  display: flex;
  align-items: center;
  background: transparent 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid transparent;
  border-radius: 0px 10px 10px 10px;
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 8px 10px 8px 10px;
}

.itp-chat-window-content__download-message-content.other {
  background: rgba(176, 219, 255, 0.45) 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid rgba(0, 134, 246, 0.2);
}

.itp-chat-window-content__download-message-content.you {
  background: rgba(31, 61, 87, 0.1) 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid rgba(112, 112, 112, 0.1);
}

.itp-chat-window-content__download-message-icon-text {
  display: flex;
  gap: 8px;
}

.itp-chat-window-content__you-message-hover {
  display: flex;
  visibility: hidden;
  margin-top: 8px;
  gap: 8px;
}

.itp-chat-window-content__you-message-container:hover
  > .itp-chat-window-content__you-message-hover {
  visibility: visible;
}

.itp-chat-window-content__other-message-container:hover
  > .itp-chat-window-content__you-message-hover {
  visibility: visible;
}

.itp-chat-window-content__you-item-hover {
  color: gray;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.itp-chat-window-content__you-item-name-hover {
  font: normal normal normal 10px/14px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
}

.itp-chat-window-content__you-item-name-hover-container {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
}

.itp-chat-window-content__you-item-hover-container:hover
  > .itp-chat-window-content__you-item-name-hover-container {
  visibility: visible;
}

em-emoji-picker {
  /* --background-rgb: 85, 170, 255;
    --category-icon-size: 24px;
    --color-border-over: rgba(0, 0, 0, 0.1);
    --color-border: rgba(0, 0, 0, 0.05);
    --font-size: 20px;
    --rgb-background: 262, 240, 283;
    --rgb-color: 102, 51, 153;
    --rgb-input: 255, 235, 235;
    --shadow: 5px 5px 15px -8px rebeccapurple; */
  --font-family: "Instrument Sans";
  --border-radius: 10px;
  --rgb-accent: 128, 128, 128;
  position: absolute;
  height: 5vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #a5a5a529;
  border: 0.30000001192092896px solid #70707073;
  opacity: 1;
  bottom: 5.6rem;
  width: 100%;
  left: 0;
}

#root section.d-flex.flex-colum {
  width: 100% !important;
}

.picker-container-left {
  position: absolute;
  top: -25rem;
  left: 25rem;
  z-index: 999;
}

.picker-container-right {
  position: absolute;
  top: -3rem;
  left: 37rem;
  z-index: 999;
}

@media (min-width: 400px) and (max-width: 460px) {
  em-emoji-picker {
    right: 10%;
  }
}

@media (max-width: 399px) {
  em-emoji-picker {
    right: 1%;
  }
}

.itp-chat-window-footer__textarea {
  border: 0;
  border-bottom: 1px solid #70707073;
  height: 40%;
  outline: none;
  resize: none !important;
  padding-top: 12px !important;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
}

.itp-chat-window-footer__textarea::placeholder {
  font: normal normal normal 12px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
}

.itp-icon-footer {
  /* color: gray; */
  cursor: pointer;
  margin-bottom: 12px;
}

.itp-chat-windiow__header-show-profile.open-menu-data {
  position: absolute;
  background: #e6e9ec 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707047;
  border-radius: 3px 0px 0px 3px;
  opacity: 1;
}

.itp-chat-menu-header {
  background: #e6e9ec 0% 0% no-repeat padding-box;
  border-radius: 0px 8px 0px 0px;
  height: 5rem;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  padding-left: 16px;
  gap: 10px;
}

.back-icon {
  position: absolute;
  left: 0rem;
  top: 2rem;
  z-index: 4;
}

.itp-chat-menu-body__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
}

.itp-chat-menu-body__icon-header {
  color: #1c5586;
}

.itp-container-label-input.itp-chat-menu-body__set-account {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.itp-chat-menu-body__input {
  margin-left: 8px;
  flex: 0 0 auto !important;
  width: 89% !important;
}

.itp-chat-menu-body__select {
  border-radius: 0 !important;
  padding: 0 !important;
  margin-top: 2px;
}

.itp-container-label-select.margin.itp-chat-menu-body__set-account {
  display: flex;
  gap: 12px;
}

.itp-chat-window__message-date {
  font: normal normal normal 10px/14px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-top: 3px;
  white-space: nowrap;
}

.itp-footer-send-button {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

.itp-footer-container-clip-icon {
  position: relative;
}

.itp-clip-icon-menu {
  position: absolute;
  bottom: 1.8rem;
  padding: 8px 4px 4px 6px;
  border-radius: 18px;
}

.itp-footer-icon-label {
  display: flex;
  cursor: pointer;
}

.add-photo-icon {
  width: 20px;
  height: 20px;
}

.add-photo-label {
  white-space: nowrap;
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-bottom: 0;
  cursor: pointer;
  margin-top: 4px;
}

.nav-link.clip.btn.btn-secondary:hover {
  background-color: transparent;
}

.clip.dropdown-menu.show {
  transform: translate(0px, -4rem) !important;
}

.sent-image {
  width: 62%;
  text-align: right;
}

.itp-chat-window-footer-img {
  max-width: 200px !important;
  height: 200px !important;
  cursor: pointer;
}

.img-full-screen {
  max-height: 90%;
  max-width: 90%;
  object-fit: contain;
}

.container-img-full-screen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.messagetemplate-property {
  width:"500px" !important;
  height:"300px" !important;
  position:"absolute" !important;
  bottom:"300px" !important;
  border-radius:"4px" !important;
  background-color:"#efeff5" !important;
  opacity:"0.5" !important;
  display:"none" !important;
}