.itp-lead-details-label {
  text-align: left;
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-lead-details-p {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}
.itp-lead-details-row-select {
  margin-top: 20px !important;
}

.itp-icon-plus {
  margin-left: 4px;
  margin-right: 6px;
}

.itp-lead-details-add-field {
  margin-top: 1.7rem;
  margin-bottom: 2rem;
}

.itp-lead-details-separator {
  border: 1px solid #70707073;
  opacity: 1;
  margin-top: -1.5rem;
}

.itp-lead-details-notes-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-lead-details-notes {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-top: 16px;
  margin-bottom: 8px;
}

.itp-lead-details-notes-button {
  margin-left: 1rem;
  white-space: nowrap;
  width: 7rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px){
  .itp-lead-details-notes-button {
    width: 40%;
  }
}

@media (max-width: 1066px){
  .itp-lead-details-notes-button {
    width: 50%;
  }
}

@media (max-width: 991px){
  .itp-lead-details-notes-button {
    width: 40%;
  }
}

@media (max-width: 742px){
  .itp-lead-details-notes-button {
    width: 70%;
  }
}



.select__placeholder {
  white-space: nowrap !important;
}

.itp-lead-details-note-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.itp-lead-details-note-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 8px;
  opacity: 1;
  padding: 12px;
  margin-bottom: 12px;
}

.itp-lead-detail-note-img {
  width: 28px;
  height: 28px;
  margin-right: 4px;
}

.itp-lead-details-note-text {
  font: normal normal normal 10px/14px Instrument Sans;
  letter-spacing: 0px;
  color: #4c4c4c99;
  opacity: 1;
  margin-right: 12px;
}

.itp-lead-details-note-content__tile {
  display: flex;
  justify-content: space-between;
}

.itp-lead-details-note-content__content {
  display: flex;
  margin-top: 8px;
}

.icon-text-note {
  margin-top: 2px;
  margin-right: 8px;
}

.itp-lead-detail-button-save {
  margin-top: 1.5rem;
  width: 8rem;
  height: 2rem;
  margin-bottom: 2r8;
}

.itp-lead-detail-add-note {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.itp-lead-details-add-note-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.itp-lead-details-save-notes-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itp-lead-details-edit-note {
  width: 100%;
  margin-top: 12px;
}

.itp-lead-details-new-field {
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 12px;
  background-color: rgba(112, 112, 112, 0.5);
}

.itp-lead-details-new-field__input {
  border: 0;
  text-align: left;
  padding-left: 0.8rem;
  background-color: transparent;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  outline: 0;
}

.itp-lead-details-new-field__input:focus {
  border-bottom: 1px solid gray !important;
}

.itp-lead-details-new-field__button {
  border: 0;
  text-align: left;
  padding-left: 0.8rem;
  background-color: transparent;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-lead-details-new-field__button:hover {
  background-color: gray !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.itp-lead-details-new-field__button.save:hover {
  background-color: gray !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.form-control {
  width: 100% !important;
}

.itp-custom-checkbox-lead-details {
  border: 1px solid gray !important;
}

.itp-container-label-select.margin {
  margin-top: 2rem !important;
}

.itp-lead-details-row-select.margin {
  margin-top: 2rem !important;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium.css-1q79v3g-MuiButtonBase-root-MuiChip-root {
  background: #51A9F01A 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  font: normal normal normal 12px Instrument Sans;
  letter-spacing: 0px;
  color: #005BA4;
  min-height: 1.5rem;
  height: auto;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconFilledColorDefault.css-i4bv87-MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  color: rgba(0, 91, 164, 0.5);
}