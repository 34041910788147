.itp-test {
  background: #f6f6f9;
  height: 100%;
}

.itp-analytics-card-report {
  padding: 12px;
}

.itp-analytics-title {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-analytics-button-report {
  width: 7rem;
  height: 2rem;
}

.itp-analytics-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-analytics-description {
  font: normal normal normal 13px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-analytics-container-select-report {
  background: #0023400a 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #0023405c;
  border-radius: 8px;
  opacity: 1;
  padding: 12px 12px 0px 12px;
}

.itp-analytics-label-select {
  font: normal normal normal 14px/19px Instrument Sans !important;
  letter-spacing: 0px !important;
  color: #3a3a3acc !important;
  opacity: 1 !important;
}

.itp-w-buton {
  width: 7.5rem;
}

.itp-w-entire {
  width: 1750px!important;
  /* width: 100%!important; */
}

.itp-alone-property {
  width: 98%!important;
  margin-left: 12px!important;
}