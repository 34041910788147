.itp-chat-list {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #b7b7b71f;
  border: 0.30000001192092896px solid #70707047;
  border-radius: 8px;
  opacity: 1;
  margin-left: 1.5rem;
  margin-top: 12px !important;
  height: 100%;
  /* margin-bottom: 1rem; */
}

@media (min-height: 1280px) {
  .itp-chat-list {
    height: 75rem;
  }
}

/* @media (min-height: 883px) and (max-height: 1279px) {
  .itp-chat-list {
    height: 60rem;
  }
} */

@media (min-height: 800px) and (max-height: 882px) {
  .itp-chat-list {
    height: 47rem;
  }
}

@media (min-height: 760px) and (max-height: 799px) {
  .itp-chat-list {
    height: 38rem;
  }
}

@media (max-height: 759px) {
  .itp-chat-list {
    height: 42rem;
  }
}

@media (min-width: 1920px) {
  .itp-chat-list {
    flex: 0 0 auto !important;
    width: 20% !important;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .itp-chat-list {
    flex: 0 0 auto !important;
    width: 30% !important;
  }
}

@media (max-width: 553px) {
  .itp-chat-list {
    margin-left: 1.3rem;
  }
}

@media (max-width: 487px) {
  .itp-chat-list {
    margin-left: 1.2rem;
  }
}
@media (max-width: 374px) {
  .itp-chat-list {
    margin-left: 1rem;
  }
}

.itp-chat-list-header {
  background: #002340 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.85;
  padding: 20px;
  height: 15%;
}

.itp-chat-list-header__text {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffffcc;
  opacity: 1;
  margin-bottom: 10px;
}

.itp-chat-list-header__select {
  background: rgba(255, 255, 255, 0.08) 0% 0% no-repeat padding-box;
  border: 0.6000000238418579px solid rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  color: #fff;
  padding: 8px 8px 8px 12px;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
}

.itp-chat-list-header__select-option {
  background: #f6f7f8 0% 0% no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 0;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-chat-list-header__container-icon {
  margin-top: 2px;
  margin-right: 10px;
}

.itp-chat-list-header__icon {
  color: #fff;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.2s ease !important;
}

.rotate-icon {
  transform: rotate(45deg);
  transition: transform 0.2s ease !important;
  width: 1.7rem !important;
  height: 1.7rem !important;
}

.itp-chat-list-header__info-icon {
  visibility: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  margin-top: 4px;
  z-index: 2;
}

.itp-chat-list-header__container-icon:hover > .itp-chat-list-header__info-icon {
  visibility: visible;
}

.itp-chat-list-body {
  height: 85%;
  cursor: pointer;
  transition: all 2s ease-in-out !important;
}

.itp-chat-list-item:hover {
  background-color: #f6f6f9;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 2px; /* Ancho del scroll */
}

.infinite-scroll-component::-webkit-scrollbar-track {
  background: #8787874d; /* Color de fondo del track (la barra detrás del scroll) */
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background: rgba(
    112,
    112,
    112,
    0.5
  ); /* Color del thumb (la barra de scroll en sí) */
}

.itp-chat-list-item {
  margin-left: 8px !important;
  margin-top: 12px !important;
  margin-right: 8px !important;
}

.itp-chat-list-item.active.row {
  background-color: rgba(31, 61, 87, 0.15);
  transition: all 0.2s ease-in-out;
}

@media (max-width: 767px) {
  .itp-chat-list-item {
    cursor: pointer;
  }
}

.itp-chat-list__col-item-img {
  padding-top: 2px !important;
}

@media (max-width: 422px) {
  .itp-chat-list__col-item-img {
    margin-right: -0.2rem;
  }
}

@media (min-width: 423px) and (max-width: 488px) {
  .itp-chat-list__col-item-img {
    margin-right: -0.8rem;
  }
}

@media (min-width: 489px) and (max-width: 549px) {
  .itp-chat-list__col-item-img {
    margin-right: -1.5rem;
  }
}

@media (min-width: 550px) and (max-width: 612px) {
  .itp-chat-list__col-item-img {
    margin-right: -2rem;
  }
}

@media (min-width: 613px) and (max-width: 683px) {
  .itp-chat-list__col-item-img {
    margin-right: -2.5rem;
  }
}

@media (min-width: 684px) and (max-width: 767px) {
  .itp-chat-list__col-item-img {
    margin-right: -3rem;
  }
}

@media (min-width: 992px) and (max-width: 1099px) {
  .itp-chat-list__col-item-img {
    margin-right: 24px;
  }
}

@media (min-width: 1100px) and (max-width: 1299px) {
  .itp-chat-list__col-item-img {
    margin-right: 6px;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .itp-chat-list__col-item-img {
    margin-right: 24px;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .itp-chat-list__col-item-img {
    margin-right: 4px;
  }
}

@media (min-width: 1500px) and (max-width: 1599px) {
  .itp-chat-list__col-item-img {
    margin-right: 2px;
  }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  .itp-chat-list__col-item-img {
    margin-right: -4px;
  }
}

@media (min-width: 1700px) and (max-width: 1799px) {
  .itp-chat-list__col-item-img {
    margin-right: -8px;
  }
}

@media (min-width: 1800px) and (max-width: 1919px) {
  .itp-chat-list__col-item-img {
    margin-right: -14px;
  }
}

@media (min-width: 1920px) {
  .itp-chat-list__col-item-img {
    margin-right: 0;
  }
}

.itp-chat-list__img-item {
  width: 3rem;
  height: 3rem;
}

.itp-chat-list__col-item-info {
  position: relative;
}

@media (min-width: 1800px) {
  /* .itp-chat-list__col-item-info {
      flex: 0 0 auto !important;
      width: 90% !important;
    } */
}

@media (min-width: 1500px) and (max-width: 1799px) {
  /* .itp-chat-list__col-item-info {
      flex: 0 0 auto !important;
      width: 88% !important;
    } */
}

.itp-chat-lit__notification {
  position: absolute;
  background-color: rgb(20, 98, 181);
  border-radius: 50%;
  height: auto;
  min-width: 1.5rem;
  right: 1rem;
  top: 1.5rem;
  min-height: 1.5rem;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffffcc;
  opacity: 1;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 4px;
}

.itp-chat-list__item-content {
  flex: 1; /* El contenido ocupa todo el espacio disponible */
  overflow: hidden; /* Ocultar el contenido que desborda */
}

.itp-chat-list__container-info-item {
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
}

.itp-chat-list__item-name {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-chat-list__no-data {
  font: normal normal normal 18px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  text-align: center;
  margin-top: 12px;
}

.itp-chat-list__item-date {
  font: normal normal normal 10px/14px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-top: 3px;
  white-space: nowrap;
}

.itp-chat-list__item-last-message {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.itp-chat-list-item-missed {
  background: #1f3d571e 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: 0px !important;
  margin-top: 12px !important;
  margin-right: 0px !important;
  padding-left: 4px;
  padding-right: 8px;
}

@media (max-width: 767px) {
  .itp-chat-list-item-missed {
    cursor: pointer;
  }
}

.itp-chat-list-item-missed__title {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  margin-bottom: 8px;
}

.itp-chat-list__item-last-missed {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #d60c0c;
  opacity: 1;
}

.itp-chat-list-circle {
  background: #002340 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: 2.5rem;
  top: 0.5rem;
}

.itp-chat-list__add-chat-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  margin-right: 1.6rem;
}

.react-tel-input.itp-chat-list__phone-input-add-chat {
  width: 85%;
  margin-left: 22px;
}

.itp-chat-list__add-chat-text {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-left: 22px;
  margin-top: 12px;
  margin-bottom: 12px;
}