.itp-chat-menu-header {
  background: #e6e9ec 0% 0% no-repeat padding-box;
  border-radius: 0px 8px 0px 0px;
  height: 5rem;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  padding-left: 16px;
  gap: 10px;
}

.itp-chat-menu-body__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
}

.itp-chat-menu-body__icon-header {
  color: #1c5586;
}

.itp-container-label-input.itp-chat-menu-body__set-account {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.itp-chat-menu-body__input {
  margin-left: 8px;
  flex: 0 0 auto !important;
  width: 89% !important;
}

.itp-chat-menu-body__select {
  border-radius: 0 !important;
  padding: 0 !important;
  margin-top: 2px;
}

.itp-container-label-select.margin.itp-chat-menu-body__set-account {
  display: flex;
  gap: 12px;
}

.back-icon {
  position: absolute;
  left: 0rem;
  top: 2rem;
  z-index: 4;
}

.itp-chat-windiow__header-show-profile {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #1c5586;
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
}

@media (max-width: 482px) {
  .itp-chat-windiow__header-show-profile {
    display: none;
  }
}

.itp-chat-windiow__header-show-profile-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 6px;
  margin-top: 12px !important;
}

.itp-chat-windiow__header-show-profile-ropdown {
  margin-top: -2px;
}

.itp-chat-windiow__header-show-profile.open-menu-data {
  position: absolute;
  background: #e6e9ec 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707047;
  border-radius: 3px 0px 0px 3px;
  opacity: 1;
}

.itp-chat-window__header-user-img {
  width: 3rem;
  height: 3rem;
}

.itp-chat-window__header-user-name {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-bottom: 0;
  white-space: normal;
}

@media (max-width: 482px) {
  .itp-chat-window__header-user-name {
    margin-left: 1.5rem;
    margin-top: 2px;
  }
}

@media (min-width: 483px) and (max-width: 617px) {
  .itp-chat-window__header-user-name {
    margin-left: 1rem;
    margin-top: 2px;
  }
}

@media (min-width: 618px) and (max-width: 767px) {
  .itp-chat-window__header-user-name {
    margin-left: 0.5rem;
    margin-top: 2px;
  }
}

@media (min-width: 768px) and (max-width: 1099px) {
  .itp-chat-window__header-user-name {
    margin-left: 1.5rem;
    margin-top: 2px;
  }
}

.itp-chat-window__header-last-message {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

@media (max-width: 1099px) {
  .itp-chat-window__header-last-message {
    display: none;
  }
}

.itp-chat-menu-input {
  background-color: transparent;
  width: 95% !important;
  margin-bottom: 5px;
}

.itp-chat-menu-container-name {
  display: flex;
  gap: 4px;
}

.itp-chat-list-edit-icon {
  width: 18px !important;
  height: 18px !important;
  margin-top: -10px;
}

.itp-chat-list-header__save-icons {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.itp-chat-list-edit-icon-container {
  visibility: hidden;
  cursor: pointer;
}

.itp-chat-menu-container-name:hover > .itp-chat-list-edit-icon-container {
  visibility: visible;
}

.itp-chat-window__header-last-message-container {
  display: flex;
  gap: 8px;
}

.itp-chat-menu__tab-images-details__container-timestap-img {
  margin-top: 12px;
}

.itp-chat-menu__tab-images-details__container-img {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.itp-chat-menu__tab-images-details__img {
  width: 80px;
  height: 80px;
  margin: 5px;
}



/* ImagesDetails.css */

.itp-chat-menu__tab-images-details__container-timestap-img {
  margin-bottom: 20px;
}

.itp-chat-menu__tab-images-details__container-img {
  display: flex;
  flex-wrap: wrap;
}

.itp-chat-menu__tab-images-details__img {
  max-width: 100px;
  max-height: 100px;
  margin: 5px;
  cursor: pointer;
}

.loading-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
}
