.itp-table-container-centered {
  max-width: 991px;
  left: 50%;
  height: auto !important;
  transform: translateX(-50%);
  transition: all 0.2s ease;
  position: relative !important;
}

.itp-table-container-centered.overflow-inherit {
  overflow: inherit!important;
}

.itp-table-container-alll {
  width: 96.8%!important;
  height: auto !important;
  overflow: auto;
  margin-top: 1rem;
  transition: all 0.2s ease;
  position: relative !important;
  margin-left: 1.8em;

}

.itp-table-container-call {
  width: 960px;
  margin-left: 30px;
}

.itp-custom-table-title-card {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin: 1rem 0 4px 1rem;
}

.itp-custom-table-subtitle-card {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin: 0 0 8px 1rem;
  border-radius: 10px;
}

/* .itp-overflow-table {
  overflow: auto;
} */

.table-responsive .dropdown-menu {
  position: static !important;
}

.itp-custom-table {
  border-collapse: collapse;
  width: 100%;
}

.itp-custom-table th,
.itp-custom-table td {
  padding: 10px 15px;
  text-align: left;
}

.itp-custom-table tr {
  /* white-space: nowrap; */
}

.itp-custom-table-thead-styles {
  background: #002340 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.85;
  transition: all 0.2s ease;
}

.itp-custom-table-th-styles {
  text-align: left;
  font: normal normal normal 14px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.itp-custom-table-td-styles {
  text-align: left;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-table-container::-webkit-scrollbar {
  width: 4px; /* Ancho del scroll */
}

.itp-table-container::-webkit-scrollbar-track {
  background: transparent; /* Color de fondo del track (la barra detrás del scroll) */
}

.itp-table-container::-webkit-scrollbar-thumb {
  background: rgba(
    112,
    112,
    112,
    0.5
  ); /* Color del thumb (la barra de scroll en sí) */
}

.dropdown-menu.show {
  background-color: #f6f6f9;
}

.itp-container-bulk-actions {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #70707029;
  border-radius: 16px;
  opacity: 1;
  margin-left: 1.8rem;
  display: inline-block;
  margin-right: 18px;
}

.itp-bulk-actions {
  display: flex;
  gap: 16px;
}

.itp-label-bulk-actions {
  text-align: left;
  font: normal normal normal 12px/22px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  margin: 4px 0 8px 10px;
}

.itp-last-icon-bulk-actions {
  margin-right: 10px;
}

.itp-icon-bulk-action {
  margin-top: 2px;
  margin-bottom: 4px;
}

.itp-bulk-icon-hover {
  visibility: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  margin-top: 4px;
  z-index: 2;
}

.itp-bulk-icon-name {
  text-align: left;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin: 10px;
}

.itp-icon-bulk-action:hover > .itp-bulk-icon-hover {
  visibility: visible;
}

.itp-custom-table-container-actionsf {
  /* max-width: 991px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: all 0.2s ease;
  margin: auto;
  margin-top: 24px;
  
}

.itp-custom-table-add-buttons {
  display: flex;
  margin-right: 0px;
  gap: 16px;
  transition: all 0.2s ease;
  margin-top: 12px;
}

.itp-custom-table-container-switchs {
  display: flex;
  gap: 2rem;
}

.itp-custom-table-switch-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  margin-right: 8px;
}

.itp-custom-table-switch .react-switch-handle {
  width: 17px !important;
  height: 17px !important;
  top: 1px !important;
  left: 1px !important;
}

.ipt-show-filter-button {
  min-width: 7.2rem;
  width: auto;
  height: 2.1rem;
}

.itp-icon-plus {
  margin-left: 4px;
  margin-right: 6px;
}

.itp-add-button {
  min-width: 10.5rem;
  width: auto;
}

.itp-add-button.centered {
  margin-right: 0px;
  margin-bottom: 1rem;
  min-width: 11rem;
  width: auto;
}

@media (min-width: 1920px) {
  .itp-add-button.centered {
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .itp-add-button.centered {
    margin-right: 7.5rem;
  }
}

.itp-custom-table-hour {
  text-align: left;
  font: normal normal normal 10px/13px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
}

.itp-custom-table-array {
  text-align: center;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  background: #51a9f01a 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  margin-right: 8px;
  display: inline-block;
  min-width: 4rem;
  width: auto;
}

.itp-custom-table-array-info {
  text-align: center;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  border-radius: 14px;
  opacity: 1;
  margin-right: 8px;
  display: inline-block;
  min-width: 4rem;
  width: auto;
}

.itp-custom-table-add-buttons.resize {
  /* margin-left: 26px;
  margin-top: 12px; */
  transition: all 0.2s ease;
}

.itp-custom-table-container-actions.resize {
  /* width: 50%; */
  transition: all 0.2s ease;
}

.itp-custom-table-container-actions.label {
  max-width: 991px;
  margin: auto;
  /* margin-top: -20px; */
}

.itp-table-container-all.resize {
  /* width: 60%;
  height: 80% !important; */
  transition: all 0.2s ease-in-out;
}

@media (max-width: 514px) {
  .select-container {
    margin-left: 26px;
    margin-top: 12px;
  }
}

@media (max-width: 514px) {
  .itp-custom-table-add-buttons {
    margin-left: 26px;
    margin-top: 12px;
    margin-right: 12rem;
    flex-direction: column;
    transition: all 0.2s ease;
  }
}

@media (max-width: 514px) {
  .itp-custom-table-add-buttons.mr-top-addButton {
    margin-right: 1.8rem;
  }
}

@media (max-width: 514px) {
  .ipt-show-filter-button,
  .itp-add-button {
    min-width: 9.5rem;
    width: auto;
    height: 2.1rem;
  }
}

@media (max-width: 816px) {
  .itp-custom-table-add-buttons {
    margin-left: 26px;
    margin-top: 12px;
    transition: all 0.2s ease;
  }
}

@media (max-width: 1049px) {
  .itp-custom-table-add-buttons {
    margin-left: 26px;
    margin-top: 12px;
    transition: all 0.2s ease;
  }
}

@media (min-width: 1920px) {
  .itp-table-container-all {
    width: 96.5%;
  }
}

@media (max-width: 700px) {
  .itp-table-container-all {
    margin-left: 1.2rem;
    transition: all 0.2s ease;
  }
}

@media (max-width: 547px) {
  .itp-table-container-all {
    margin-left: 0.8rem;
    transition: all 0.2s ease;
  }
}

@media (max-width: 469px) {
  .itp-table-container-all {
    margin-left: 0.3rem;
    transition: all 0.2s ease;
  }
}

@media (max-width: 514px) {
  .itp-table-container-all {
    transition: all 0.2s ease;
  }
}

.itp-custom-table-row {
  visibility: visible;
}

.itp-custom-table-row:hover {
  cursor: pointer;
  background-color: rgba(67, 112, 151, 0.2);
}

.dropdown-item {
  visibility: visible !important;
}

.dropdown-item:hover {
  background-color: rgba(128, 128, 128, 0.1) !important;
}

.email-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
}

.text-copy {
  margin-top: -14px;
  position: absolute;
  text-align: center;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: black;
  background: #51a9f01a 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  margin-right: 8px;
  display: inline-block;
  min-width: 4rem;
  width: auto;
  visibility: hidden;
}

.container-copy:hover ~ .text-copy {
  visibility: visible;
}

.container-pagination {
  margin-left: 1.75rem;
  margin-top: 12px;
}

.dropdown-menu.show.max-length-six {
  position: fixed !important;
}

.container-footer-table {
  display: flex;
  gap: 1.5rem;
}

.container-item-per-page {
  margin-top: 12px;
}

/* @media (max-width: 514px) {
  .container-item-per-page {
    margin-top: 0;
  }
}

@media (max-width: 368px) {
  .container-item-per-page {
    margin-left: -6px;
  }
} */

.itp-custom-table-no-data {
  text-align: center;
  font: normal normal 600 18px Instrument Sans;
  letter-spacing: 0px;
  color: #437097 !important;
  opacity: 1;
  cursor: pointer;
}

.itp-custom-table-container-no-data {
  width: 90%;
  margin-left: 2.7rem;
  margin-top: 12px;
  margin-bottom: 12px;
}

.itp-custom-table-label {
  text-align: left;
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-left: 2rem !important;
  margin-bottom: 12px;
  margin-top: 12px;
}

.itp-custom-table-container-search-input {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  border: 1px solid #3a3a3a99;
  border-radius: 16px;
  opacity: 1;
  width: 30rem;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.itp-custom-table-container-search-input.focused-input {
  border: 1px solid #002340;
}

.itp-custom-table-search-icon {
  width: 18px !important;
  height: 18px !important;
  color: #3a3a3acc;
  margin-right: 8px;
  margin-top: 2px;
}

.itp-custom-table-search-input {
  border: 0;
  outline: none;
  background-color: transparent;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  width: 100%;
}

input.itp-custom-table-search-input::placeholder {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-custom-table-title {
  text-align: left;
  font: normal normal 600 24px/32px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-bottom: 0;
  margin-left: 2rem;
}

@media (max-width: 700px) {
  .itp-custom-table-label,
  .itp-custom-table-title,
  .itp-custom-table-container-search-input,
  .itp-custom-table-pagination {
    margin-left: 1rem !important;
  }
}

@media (max-width: 469px) {
  .itp-custom-table-label,
  .itp-custom-table-title,
  .itp-custom-table-container-search-input,
  .itp-custom-table-pagination {
    margin-left: 1rem !important;
  }
}

.itp-custom-table-label.centered {
  margin-left: 0px !important;
}

.itp-custom-table-container-search-input.centered {
  margin-left: 14rem !important;
}

.itp-custom-table-title.centered {
  max-width: 991px;
  margin:auto;
  font-size: 18px;
  font-weight: bold;
}

.itp-custom-table-pagination.centered {
  margin-left: 14rem;
}

@media (min-width: 992px) and (max-width: 1009px) {
  .itp-custom-table-label.centered,
  .itp-custom-table-title.centered,
  .itp-custom-table-pagination.centered,
  .itp-custom-table-container-search-input.centered {
    margin-left: 9.5rem !important;
  }
}

@media (min-width: 1010px) and (max-width: 1099px) {
  .itp-custom-table-label.centered,
  .itp-custom-table-title.centered,
  .itp-custom-table-pagination.centered,
  .itp-custom-table-container-search-input.centered {
    margin-left: 10rem !important;
  }
}

@media (min-width: 1100px) and (max-width: 1129px) {
  .itp-custom-table-label.centered,
  .itp-custom-table-title.centered,
  .itp-custom-table-pagination.centered {
    margin-left: 11rem !important;
  }
}

@media (min-width: 1130px) and (max-width: 1199px) {
  .itp-custom-table-label.centered,
  .itp-custom-table-title.centered,
  .itp-custom-table-pagination.centered ,
  .itp-custom-table-container-search-input.centered {
    margin-left: 11.5rem !important;
  }
}

@media (min-width: 1920px) {
  .itp-custom-table-label.centered,
  .itp-custom-table-title.centered,
  .itp-custom-table-pagination.centered,
  .itp-custom-table-container-search-input.centered {
  }
}

/* .dropdown-menu.centered {
  position: fixed !important;
  inset: auto 0px 0px auto !important;
  transform: translate(-126px, -45px) !important;
  width: auto !important;
  z-index: 9999;
} */

a.redirect {
  text-decoration: none !important;
}

a.redirect {
  color: #3a3a3acc !important; /* Puedes utilizar el color que desees aquí */
}

a.redirect:hover {
  color: #002340 !important;
}

.container-footer-table.gap-0 {
  gap: 0;
}

.text-align-with-table {
  padding-left: 30px;  /* Adjust XX based on the padding of the CustomTable */
  padding-right: 0px; /* Adjust XX based on the padding of the CustomTable */
}

.table-alt-text-title {
  font-weight: 600; /* boldness */
  font-size: 18px;
  line-height: 24px;
  font-family: 'Instrument Sans', sans-serif; /* Assuming Instrument Sans is available in your project */
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  text-align: left;
}

.table-alt-text-title-description {
  font-weight: 500; /* medium weight */
  font-size: 14px;
  line-height: 19px;
  font-family: 'Instrument Sans', sans-serif; /* Assuming Instrument Sans is available in your project */
  letter-spacing: 0px;
  color: #3A3A3ACC; /* The CC at the end defines the opacity of the color. This could be 80% opacity for this particular color */
  opacity: 1;
  text-align: left;
}

.zIndexMini{
  z-index: -1;
}

/* .dropdown-menu.centered.show {
  inset: auto 0px 0px auto !important;
} */