.itp-edit-callFlow-numbers-add-button {
  padding-top: 0 !important;
  width: 8rem !important;
  height: 2rem;
  margin-right: 2rem;
}

.itp-edit-callFlow-numbers__numbers {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.itp-edit-callFlow-numbers-switch {
  margin-right: 2rem;
}
