.itp-callFlow-menu-container-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.itp-callFlow-menu-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 8px;
  opacity: 1;
  width: 10rem;
  height: 4rem;
  cursor: pointer;
}

.itp-callFlow-menu-action.selected {
  border: 0.3px solid var(--unnamed-color-002340);
  background: #0023400a 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #002340;
  border-radius: 8px;
  opacity: 1;
}

.itp-callFlow-menu-action:hover {
  background-color: #0023400a;
}

.itp-callFlow-menu-name {
  font: normal normal normal 12px/12px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-callFlow-menu-back-selected {
  color: #437097;
  cursor: pointer;
}
