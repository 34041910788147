.phone-numbers-container-search-input {
    display: flex;
    align-items: center;
    border: 1px solid #3a3a3a99;
    border-radius: 16px;
    opacity: 1;
    width: 30rem;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 10px;
    /* height: 1rem; */
  }

  .itp-phone-numbers-transfer-button {
    margin-top: 12px;
    width: 10rem !important;
    background-image: none !important;
    white-space: nowrap;
    height: 1.9rem;
    padding-top: 3.5px !important;
    margin-left: 12px;
    background-color: transparent !important;
  }

  .itp-phone-numbers-transfer-button:hover {
    background-color: #fff !important;
  }