.my-select__control.css-13cymwt-control {
  border-radius: 16px;
  height: 2.5rem;
}

.my-select__indicator-separator.css-1u9des2-indicatorSeparator {
  display: none;
}

.my-select__control.my-select__control--is-focused.select__control--menu-is-open.css-t3ipsp-control {
  border-radius: 16px !important;
  border: 0.5px solid #1976d2 !important;
}

.my-select__control.my-select__control--is-focused.css-t3ipsp-control {
  border-radius: 16px !important;
  border: 0.5px solid #1976d2 !important;
  height: 2.5rem;
}

.my-select__indicators.css-1hb7zxy-IndicatorsContainer {
  margin-top: -1.1rem;
}

.my-select__value-container.my-select__value-container--has-value.css-1fdsijx-ValueContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 4px;
}

.my-select__placeholder {
  white-space: nowrap !important;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 4px;
}

.itp-filter-title {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-filter-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.my-multiselect__control.css-13cymwt-control {
  border-radius: 16px;
  height: auto;
  min-height: 2.5rem;
}

.my-multiselect__indicator-separator.css-1u9des2-indicatorSeparator {
  display: none;
}

.my-multiselect__control.my-multiselect__control--is-focused.select__control--menu-is-open.css-t3ipsp-control {
  border-radius: 16px !important;
  border: 0.5px solid #1976d2 !important;
}

.my-multiselect__control.my-multiselect__control--is-focused.css-t3ipsp-control {
  border-radius: 16px !important;
  border: 0.5px solid #1976d2 !important;
  height: auto;
}

.my-multiselect__value-container.my-multiselect__value-container--has-value.css-1fdsijx-ValueContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 4px;
}

.my-multiselect__placeholder {
  white-space: nowrap !important;
  height: 0.5rem;
  margin-top: 2px;
}

.checbox-filter {
  border: 1px solid gray !important;
}

.container-filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.container-date-range {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #437097;
  border-radius: 18px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 6px 0 6px 12px;
}

.date-range-label {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  margin-right: 4px;
}

.date-range-separator {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  border: 0;
  margin-left: 12px;
  margin-right: 12px;
}

.date-range-input {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  border: 0;
  outline: none;
  background-color: transparent;
}

@media (max-width: 1919px) {
  .itp-filter-label {
    margin-bottom: 8px;
    margin-top: 12px;
  }
}

.name-date-range {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-right: 4px;
  white-space: nowrap;
}
