.itp-test {
  background: #f6f6f9;
  height: 100%;
}

.container-table-contact-list {
  margin-top: 16px;
  margin-bottom: 20px;
}

.itp-advanced-callFlows__flows {
  width: 95%;
  margin-left: 1.8rem;
  transition: all 0.2s ease;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0.5px solid #7070706e;
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 2rem;
  min-height: 100%;
  height: auto;
}

.itp-advanced-callFlows__flows.open-panel {
  width: 59%;
}

.itp-advanced-callFlows__flows.full-screen {
  margin: 0 !important;
  width: 100% !important;
  border-radius: 0 !important;
}

@media (min-width: 1920px) {
  .itp-advanced-callFlows__flows {
    width: 96.5%;
  }
}

@media (min-width: 1920px) {
  .itp-advanced-callFlows__flows {
    width: 96.5%;
  }
}

@media (max-width: 700px) {
  .itp-advanced-callFlows__flows {
    margin-left: 1.2rem;
    transition: all 0.2s ease;
  }
}

@media (max-width: 547px) {
  .itp-advanced-callFlows__flows {
    margin-left: 0.8rem;
    transition: all 0.2s ease;
  }
}

@media (max-width: 469px) {
  .itp-advanced-callFlows__flows {
    margin-left: 0.3rem;
    transition: all 0.2s ease;
  }
}

@media (max-width: 514px) {
  .itp-advanced-callFlows__flows {
    transition: all 0.2s ease;
  }
}

.itp-advanced-callFlows__flow-top-bar {
  background: #002340 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.85;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 20px;
}

.itp-advanced-callFlows__flow-top-bar.full-screen {
  border-radius: 0 !important;
}

.itp-advanced-callFlows__flow-top-bar-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.itp-advanced-callFlows__flow-top-bar-actions {
  display: flex;
  gap: 12px;
}

.itp-advanced-callFlows__flow-top-bar-button {
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #ffffff;
  border-radius: 16px;
  opacity: 1;
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background-color: transparent;
  padding: 6px;
  width: 8rem;
}

.itp-advanced-callFlows__flow-top-bar-button:hover {
  background-color: rgba(67, 112, 151, 0.3);
}

.itp-advanced-callFlows__flow-top-bar-icon {
  cursor: pointer;
  transition: all 0.2s ease;
}

.itp-advanced-callFlows__flow-top-bar-icon.white {
  color: #fff;
  transition: all 0.2s ease;
}
