.dropdown >.CustomDropDown{
    background: transparent!important;
    height: 30px !important;
    border: none;
    border-right: 1px solid #ccc !important;
    color: black !important;
}

.dropdown >.CustomDropDown:hover{
    background: #D8D8D8 !important;
    height: 30px !important;
    border: none;
    border-right: 1px solid #ccc !important;
    color: black !important;
}

.CustomDropDownItem:active{
    background: #D8D8D8 !important;
    color: black !important;
}

button.CustomDropDown.dropdown-toggle.btn.btn-secondary {
    height: 30px !important;
    padding: 5px 5px !important;
}

.dropdown-menu.show{
    border-radius: 0px !important;
}

button.CustomDropDown.btn.btn-secondary {
    background: transparent !important;
    color: black;
    border: none;

}

button.CustomDropDown.btn.btn-secondary {
    padding: 5px 5px !important;

}
.dropDownTextAreaSelect.dropdown:nth-of-type(1) {
    border-left:1px solid #D8D8D8 !important ;
}