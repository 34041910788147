body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icon-hover {
  cursor: pointer;
}

.icon-hover:hover {
  transform: scale(1.2);
}

.icon-hover:active {
  transform: scale(0.8);
}

.btn-light {
  font: normal normal normal 14px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #437097;
  border-radius: 16px;
  opacity: 1;
  background-color: transparent;
  min-width: 5rem;
  min-height: 2rem;
}
.btn-light:hover {
  background-color: lightgrey;
}

.btn-primary {
  font: normal normal normal 14px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #437097 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 16px;
  border: 0;
  min-width: 5rem;
  min-height: 2rem;
  display: inline-block;
}

.btn-primary:hover {
  background-color: #002340;
}

.btn-primary:disabled {
  background-color: rgba(67, 112, 151, 0.5);
}

.form-select-itp {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #437097;
  border-radius: 18px;
  display: block;
  width: 100%;
  padding: 8px 8px 8px 12px;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.form-select-itp-error {
  border: 1px solid red !important;
}

.form-control-itp {
  border: none;
  border-bottom: 1px solid #437097;
  outline: none;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  width: 100%;
  background-color: transparent;
}

.form-control-itp-error {
  border: 1px solid red !important;
}

::file-selector-button {
  border: 0 !important;
  margin-top: 18px;
  font: normal normal normal 14px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #437097 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;  
  border: 0;
  min-width: 5rem;
  min-height: 2rem;
  display: inline-block;
  cursor: pointer !important;
}

.form-control-itp:focus {
  border-bottom: 1px solid #1976d2;
}

.form-select-itp:focus {
  border: 1px solid #1976d2;
}

.itp-component-tag-container {
  display: flex;
  gap: 12px;
  border-bottom: 1px solid #437097;
}

.itp-component-tag-width {
  width: 100%;
}

.itp-component-tag-img {
  margin-top: 4px;
  width: 1.5rem;
}

.itp-component-tag-label {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root::before {
  border: 0 !important;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root::after {
  border: 0 !important;
}

.itp-component-tag-container:focus-within {
  border-bottom: 1px solid #002340;
}

.itp-container-label-input {
  border-bottom: 1px solid #437097;
  margin: 0 0 16px 0 !important;
}

.itp-container-label-input-error {
  border-bottom: 1px solid red !important;
}

.itp-container-label-select {
  margin: 0 0 16px 0 !important;
}

.itp-container-label-input:focus-within {
  border-bottom: 1px solid #002340;
}

.itp-label-input-col {
  padding-left: 0 !important;
}

.itp-label-input__label {
  text-align: left;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 8px;
}

.itp-label-input__input {
  border: 0 !important;
  width: 100%;
}

.form-control-itp.itp-label-input__input:focus {
  border-bottom: 0 !important;
}

.btn-primary.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-check-input.border {
  border: 1px solid gray !important;
}

.modal-header {
  color: #437097 !important;
}

.react-tel-input .form-control {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 0.30000001192092896px solid #437097 !important;
  border-radius: 18px !important;
  opacity: 1 !important;
}

.react-tel-input .form-control:focus {
  border: 0.30000001192092896px solid #1976d2 !important;
}

.react-tel-input .flag-dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #437097;
  opacity: 1;
}

.react-tel-input .flag-dropdown:focus {
  border: 0.30000001192092896px solid #1976d2 !important;
}

.react-tel-input .flag-dropdown:active {
  border: 0.30000001192092896px solid #1976d2 !important;
}

.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
}

.alert-background {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 16px;
  height: 31px;

}

.itp-alert-text {
  font-family: "Instrument Sans";
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  letter-spacing: 0px;
  color: #3A3A3ACC;
  opacity: 1;
  margin-left: 3px;
  margin-right: 3px;

}

.itp-alert-data {
  float: left;
  margin-left: 5px;
  margin-top: 1px;
  
}

.itp-alert-icon {
  float: left;
}

.bgc-transparent {
  background-color: transparent !important;
}