.itp-table-container-centered.itp-general-settings {
  overflow: inherit;
  z-index: 1;
}

/* -------------- api-keys class ----------------- */

.itp-api-key__add-key-row {
  margin-top: 2rem;
}

.itp-api-key__add-title {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-api-key__add-text {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-api-key__add-button {
  width: 8rem;
}

.itp-api-key-info-table {
  margin-bottom: 0;
  margin-top: 12px;
}

@media (min-width: 1920px) {
  .itp-custom-table-add-buttons.itp-api-key__add-button-table {
    flex: 0 0 auto !important;
    width: 29.5% !important;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

#campaigns {
  margin-bottom: 2rem;
}

.itp-general-settings-update-alert.open {
  position: fixed;
  right: 0;
  top: 80px;
  background-color: #fff;
  width: 14rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  z-index: 9999;
  padding: 8px;
  transform: translateX(0%);
  transition: transform 0.2s;
}

.itp-general-settings-update-alert.hidden {
  position: fixed;
  right: 0;
  top: 80px;
  background-color: #fff;
  width: 14rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  z-index: 9999;
  padding: 8px;
  transform: translateX(100%);
  transition: transform 0.2s;
}

.itp-general-settings-update-alert-label {
  text-align: left;
  font: normal normal normal 14px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}
