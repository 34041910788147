.itp-test {
  background: #f6f6f9;
  height: 100%;
}

.itp-livePanel-table-call-praking {
  margin-bottom: 2rem;
}

.itp-livePanel-queues-col {
  margin-top: 2.5rem;
  margin-right: 1.6rem;
  margin-bottom: 2rem !important;
  width: 97%!important;
}

.itp-livePanel-queue-title {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-livePanel-queue-subtitle {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-livePanel-card-container-queues {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 6px #00000029 !important;
  border: 0.5px solid #7070706e !important;
  border-radius: 8px !important;
}

.itp-livePanel-queue-card {
  min-width: 300px;
  margin-right: 10px;
}

.itp-livePanel-queue-div-container {
  margin: 0rem 10px 0 1rem;
  padding: 5px;
}

.queue-change-background {
  background: #51a9f019 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0.5px solid #7070706e;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
}

.itp-livePanel-queue-name {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-livePanel-queue-collapse {
  padding: 5px;
  margin-bottom: 0.1rem;
}

.itp-livePanel-queue-toggle-collapse {
  display: flex;
}

.itp-livePanel-queue-label-collapse {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
  margin-bottom: 0;
}

.itp-livePanel-queue-icon-collapse {
  cursor: pointer;
  color: #437097;
  transition: all 0.2s !important;
}
