.select-container {
    position: relative;
    display: inline-block;
  }
  
  .select-header {
    cursor: pointer;
    display: block;
    width: auto;
    min-width: 150px;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #393939;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    appearance: none;
    border: 0.6000000238418579px solid #2e475d;
    border-radius: 16px;
    opacity: 1;
  }
  
  .select-header:hover {
    background-color: #fff;
    border-radius: 16px;
  }
  
  .select-header.active-menu {
    background-color: #fff;
    border-radius: 16px;
  }
  
  .select-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 2;
    background-color: #fff;
  }
  
  .select-option {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    justify-content: space-between;
  }
  
  .select-option:hover {
    background-color: #f5f5f5;
  }
  
  /* .select-option.selected {
      background-color: transparent;
    } */
  
  .select-option input[type="checkbox"] {
    margin-left: 8px;
  }
  