.itp-page-container {
  display: flex;
  /* height: calc(100%) !important; */
  position: absolute;
  width: 100%;
  margin-top: 70px;
}

.itp-width-sidebar {
  margin-left: 233px;
  width: calc(100% - 233px) !important;
  position: relative !important;
  height: 100% !important;
  transition: all 0.5s;
}

.itp-width-100 {
  width: 100% !important;
  margin-left: 0 !important;
  transition: width 0.2s ease;
}

@media (max-width: 991px) {
  .itp-width-sidebar {
    width: 100% !important;
    margin-left: 0;
    transition: all 0.5s;
  }
}
