.itp-test {
  background: #f6f6f9;
  height: 100%;
}

.board {
  width: 240px;
  height: 120px;
}

h6,
p {
  font-family: sans-serif;
  font-size: 13px;
}

.itp-dashboard-chart__card-body.card-body {
  padding-left: 0;
  padding-bottom: 0;
}
