.icoming-call {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #002340;
  color: #F6F7F7;
  width: 300px;
  height: 300px;
  border-radius: 0px;
}

.header-icoming-call {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  height: 30px;
  background: #437097;
  padding-top: 3px;
  font-size: 14px;
}

.left-top-date-incoming-call {
  margin-left: 10px;
}

.incoming-call-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.incoming-call-user {
  font-size: 40px;
  cursor: pointer;

}

.x-button {
  margin: 0px 10px;
}

.top-data-icoming-call {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* padding: 20px; */
}

.left-top-date-incoming-call {
}

.right-top-date-incoming-call {
  display: flex;
}

.top-date-time {
  color: #8ba4b5;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 0px 10px;
  width: 100%;
}

.buttons-actions-incoming-call {
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 14px;
  margin-top: 30px;
  width: 100px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.reject {
  background-color: #f44336;
}

.answer {
  background-color: #4caf50;
}

.buttons-actions-incoming-call:hover {
  opacity: 0.9;
}
.buttons-actions-incoming-call:active {
  opacity: 0.6;
}