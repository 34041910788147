.go2791332690 {
  color: #437097 !important;
  font-size: 18px;
}

.step-form-horizontal ol .go3842760039 span {
  border-color: #437097 !important;
  background: #437097 !important;
  color: white !important;
}

.step-form-horizontal ol .go433304200 span {
  border-color: #437097 !important;
  background: #437097 !important;
  color: white !important;
}

.go433304200:before {
  background: #437097 !important ;
  width: 100% !important;
}

.StepButton-d0-0-2-6.active {
  background-color: #437097 !important;
}

.StepButton-d0-0-2-6.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}

.StepButton-d2-0-2-12.active {
  background-color: #437097 !important;
}

.StepButton-d2-0-2-12.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}

.StepButton-d4-0-2-20.active {
  background-color: #437097 !important;
}

.StepButton-d4-0-2-20.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}

.StepButton-d6-0-2-26.active {
  background-color: #437097 !important;
}

.StepButton-d6-0-2-26.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}

.StepButton-d8-0-2-32.active {
  background-color: #437097 !important;
}

.StepButton-d8-0-2-32.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}

.StepButton-d10-0-2-36.active {
  background-color: #437097 !important;
}

.StepButton-d10-0-2-36.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}

.StepButton-d12-0-2-42.active {
  background-color: #437097 !important;
}

.StepButton-d12-0-2-42.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}

.StepButton-d14-0-2-48.active {
  background-color: #437097 !important;
}

.StepButton-d14-0-2-48.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}

.StepButton-d16-0-2-54.active {
  background-color: #437097 !important;
}

.StepButton-d16-0-2-54.completed {
  background-color: rgba(67, 112, 151, 0.5) !important;
}