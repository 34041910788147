.audio-message {
  margin: 10px;
  display: flex;
  background: transparent 0% 0% no-repeat padding-box;
  /* border: 0.30000001192092896px solid rgba(112, 112, 112, 0.45); */
  border-radius: 10px 0px 10px 10px;
  width: auto;
  position: relative;
}

.audio-message.border0 {
  border: 0;
}

.audio-message-image {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  position: absolute;
  left: 16px;
}

.container-audio {
  margin-left: 2.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* border: 0.4000000059604645px solid #707070b5; */
  border-radius: 6px;
}

.container-audio.w100 {
  width: 100%;
  border-radius: 10px 0px 10px 10px;
}

.audio-controls {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.play-pause {
  cursor: pointer;
  margin-right: 10px;
}

.audio-message-icon {
  margin-top: 12px;
  color: #437097;
  margin-right: -4px;
  margin-left: 16px;
}

.audio-message-icon.ml-0 {
  margin-left: 0;
}

.progress-bar-container {
  width: 10rem;
  position: relative;
  top: -23px;
  margin-right: 12px;
}

.progress-bar-container.w100 {
  width: 100%;
}

.progress-bar-background {
  height: 100%;
  background-image: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.05) 50%);
  background-size: 10px 100%;
}

.waveform-canvas {
  width: 100%;
  height: 50px;
  position: relative;
}

.progress-bar {
  height: 20px;
  width: 100%;
  background-color: transparent !important;
  top: 0;
  left: 0;
  cursor: pointer;
  position: relative !important;
  overflow: hidden !important;
  margin-top: 4px;
  z-index: 2;
}

.progress-bar.active {
  background-color: rgba(
    67,
    112,
    151,
    0.2
  ); /* Cambia el color según tu preferencia */
}

.progress-filled {
  height: 100%;
  background-color: transparent;
  position: relative;
}

.progress-marker {
  width: 10px;
  height: 10px;
  background-color: #437097;
  position: absolute;
  top: 7px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.volume-slider {
  margin-top: -24px;
  width: 30% !important; /* Ajusta el ancho según tu diseño */
}

.volume-slider.out {
  width: 3rem !important;
}

.volume-icon {
  margin-top: -27px;
  cursor: pointer !important;
  transition: all 0.2s !important;
  width: 19px !important;
  height: 19px !important;
  margin-left: 16px;
}

.container-volumen-icon {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #3a3a3a;
  margin-left: 4px;
}

.container-volumen-icon.out {
  margin-top: 1rem;
  margin-left: -12px;
}

.css-eg0mwd-MuiSlider-thumb {
  width: 8px !important;
  height: 8px !important;
}

.custom-slider-root {
  color: #437097 !important; /* Cambia el color del icono redondo */
}

.custom-slider-track {
  color: #437097; /* Cambia el color de la línea */
}

.time-info {
  font-size: 12px;
  margin-top: -6px;
  margin-left: 20px;
}
