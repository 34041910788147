.itp-tab-menu {
  width: 100%;
  /* border: 1px solid #ccc; */
  /* margin-top: 70px; */
}

.v-hidden {
  visibility: hidden;
}

.itp_tab_menu__container-text-back {
  margin-top: 4px;
  margin-left: 12px;
  cursor: pointer;
}

.itp_tab_menu__text-back {
  text-align: left;
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
}

.itp-tab-menu__tabs-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-right: 20px;
}

.itp-tab-menu__tabs {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.itp-tab-menu__tab {
  cursor: pointer;
  padding: 10px 20px;
  position: relative;
  font-size: 12px;
}

.itp-tab-menu__tab.active {
  color: #002340;
}

.itp-tab-menu__tab.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #002340;
  border-radius: 10px 10px 0 0;
}

.itp-tab-menu__tab.active::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 4px;
  background-color: red;
  border-radius: 0 0 10px 10px;
}

@media (max-width: 431px) {
  .itp-tab-menu__tabs {
    overflow: auto;
    margin-top: 10px;
  }
}

.itp-tab-menu__content {
  height: 100%;
}