.itp-data-report-card-data {
  height: 3rem !important;
}

.itp-data-report-date {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-right: 2rem;
}

.itp-data-report-range-date {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.form-select-itp.itp-call-logs-select {
  width: 10rem;
  margin-top: 20px;
}
