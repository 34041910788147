.itp-test {
  background: #f6f6f9;
  height: 100%;
}

.itp-all-chats {
  display: flex;
}

.itp-audio-message {
  width: 100%;
  margin-top: 3rem;
}

.all-container-chat {
  width: 100%;
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  height: calc(100vh - 90px);
  transition: width 0.2s ease;
}

.all-container-chat.resize {
  width: 40%;
  transition: width 0.2s ease;
}

@media (min-width: 1920px) {
  .all-container-chat.resize {
    width: 54%;
  }
}

@media (min-width: 768px) and (max-width: 1099px) {
  .all-container-chat {
    width: 53%;
  }
}

/* @media (min-height: 1280px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 75rem !important;
  }
} */

/* @media (min-height: 883px) and (max-height: 1279px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 60rem !important;
  }
} */

/* @media (min-height: 800px) and (max-height: 882px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 47rem !important;
  }
} */

@media (min-height: 760px) and (max-height: 799px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 38rem !important;
  }
}

/* @media (max-height: 759px) {
  .all-container-chat,
  .all-container-menu,
  .all-container-menu-absolute {
    height: 42rem !important;
  }
} */

.all-container-menu {
  width: 0%;
  margin-top: 12px;
  margin-right: 12px;
  height: 57rem;
  margin-left: -0.7rem;
  overflow: auto;
}

.all-container-menu-absolute {
  width: 30%;
  margin-top: 12px;
  height: 30rem;
  position: absolute;
  right: 0;
  transform: translateX(0%);
  transition: transform 0.2s ease-in-out;
  display: block;
  z-index: 2;
}

@media (min-width: 1200px) and (max-width: 1344px) {
  .all-container-menu-absolute {
    width: 35%;
  }
}

@media (min-width: 1067px) and (max-width: 1199px) {
  .all-container-menu-absolute {
    width: 40%;
  }
}

@media (min-width: 992px) and (max-width: 1066px) {
  .all-container-menu-absolute {
    width: 45%;
  }
}

@media (min-width: 877px) and (max-width: 991px) {
  .all-container-menu-absolute {
    width: 38%;
  }
}

@media (min-width: 768px) and (max-width: 876px) {
  .all-container-menu-absolute {
    width: 44%;
  }
}

@media (min-width: 667px) and (max-width: 767px) {
  .all-container-menu-absolute {
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 666px) {
  .all-container-menu-absolute {
    width: 58%;
  }
}

@media (max-width: 575px) {
  .all-container-menu-absolute {
    width: 100%;
  }
}

@media (min-width: 1920px) {
  .all-container-menu {
    width: 22%;
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {
  .all-container-menu {
    width: 30%;
  }
}

.no-show {
  width: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease;
  position: absolute;
  visibility: hidden;
}

.itp-contact-details-menu {
  width: 24px;
  height: 24px;
}

.chat-menu-label {
  margin-top: 8px;
}