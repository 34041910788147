.oncall {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #002340;
  color: #f6f7f7;
  width: 300px;
  border-radius: 0px;
  border-radius: 20px;
  font-size: 14px;
}

.oncall-close {
  height: 180px;
  position: relative;

}

.oncall-open {
  height: 500px;
}
.oncall-header {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}

.oncall-top-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px 0px 20px;
}

.oncall-ellipsis {
  font-size: 30px;
  margin-top: -12px;
}

.oncall-call-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px 5px 20px;
}

.oncall-span {
  color: #8ba4b5;
  margin-left: 10px;
  cursor: pointer;
}

.oncall-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.oncall-buttons-item {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
}

.oncall-buttons-item:hover {
  opacity: 0.9;
}

.oncall-buttons-item:active {
  opacity: 0.6;
}

.oncall-buttons-item-mic {
  background: #4caf50;
}

.oncall-buttons-item-grip {
  background: #005398;
}

.oncall-buttons-item-hand {
  background: #005398;
}

.oncall-buttons-item-bye {
  background: #f44336;
}
.oncall-buttons-item-bye {
  rotate: 225deg;
}

.oncall-keypad {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.oncall-keypad button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.oncall-buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  width: 100%;
}

.oncall-buttons-actions {
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
}


.oncall-buttons-actions:hover {
  opacity: 0.9;
}

.oncall-buttons-actions:active {
  opacity: 0.6;
}

.oncall-transfer {
  background-color: #4caf50;
}

.oncall-cancel {
  background-color: #f44336;
}

.oncall-button-keyboard{
  padding: 15px 30px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.oncall-number{
  width: 100%;
  display: flex;
  position: relative;
  font-size: 16px;
  /* margin-top: 20px; */
  height: 20px;
  /* justify-content: space-between; */
}

.oncall-number-span{
  width: 100%;
  display: flex;
  justify-content: center;
 height: 0px; 
}

.oncall-number-delete{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 5px;
  cursor: pointer;
}

.oncall-number-delete div{
  cursor: pointer;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn.dropdown-toggle-split:first-child, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: transparent;
  border: none;
  padding: 0px;
}

.oncall-button-bye-call{
  padding: 3px;
  border-radius: 50%;
  top: 4px;
  position: relative;
  margin-left: 5px;
  cursor: pointer;
}

.rotate{
  rotate: 250grad;

}

.oncall-button-bye-call:hover{
  opacity: 0.5;
}

.onConference {
  position: absolute;
  right: 20px;
  top: 65px;
}